<template>
  <b-card title="Patient Info" class="patient-info">
    <b-form @submit.prevent class="px-2">
      <b-row>
        <b-col md="6">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Hospital ID"
                label-for="v-hospital-id"
                label-cols-md="4"
              >
                <v-select
                  v-model="hospital"
                  label="hospital_id"
                  :options="hospitals"
                  :clearable="false"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col cols="12">
              <b-form-group
                label="Last Name"
                label-for="last_name"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.last_name"
                  id="last_name"
                  type="text"
                  placeholder="Last Name"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="First Name"
                label-for="first_name"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.first_name"
                  id="first_name"
                  type="text"
                  placeholder="First Name"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col> -->
            <b-col cols="12">
              <b-form-group
                label="Date of birth"
                label-for="v-birth"
                label-cols-md="4"
              >
                <b-input-group class="mb-1">
                  <b-form-input
                    ref="date_of_birth"
                    id="example-input"
                    v-model="patientInfo.date_of_birth"
                    type="text"
                    placeholder="DD.MM.YYYY"
                    v-mask="dateMask"
                    autocomplete="off"
                    show-decade-nav
                    :readonly="readOnly"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="date"
                      value-as-date
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      locale="de"
                      aria-controls="example-input"
                      :disabled="readOnly"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Gender" label-for="gender" label-cols-md="4">
                <v-select
                  v-model="patientInfo.gender"
                  :options="genders"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Height[cm]"
                label-for="height"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.height_meter"
                  id="height"
                  type="number"
                  placeholder="Height"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Weight[kg]"
                label-for="weight"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.weight_kg"
                  id="weight"
                  type="number"
                  placeholder="Weight"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="BMI" label-for="bmi" label-cols-md="4">
                <div class="vertical-align">
                  {{ bmi }}
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="KÖF DuBois"
                label-for="dubois"
                label-cols-md="4"
              >
                <div class="vertical-align">
                  {{ kof }}
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Patient ID"
                label-for="patient_id"
                label-cols-md="4"
              >
                <div class="vertical-align">
                  {{ patientInfo.patient_id }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <!-- <b-col cols="6">
          <b-card class="bordered-card" title="Contact data">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="City"
                  label-for="city"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.city"
                    id="city"
                    type="text"
                    placeholder="City"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="ZIP code"
                  label-for="zip_code"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.zip_code"
                    id="zip_code"
                    type="text"
                    placeholder="ZIP code"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Street"
                  label-for="street"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.street"
                    id="street"
                    type="text"
                    placeholder="Street"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="House number"
                  label-for="house_number"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.house_number"
                    id="house_number"
                    type="text"
                    placeholder="House number"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Phone"
                  label-for="phone"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.phone"
                    id="phone"
                    type="text"
                    placeholder="Phone"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Email"
                  label-for="email"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="patientInfo.email"
                    id="email"
                    type="text"
                    placeholder="Email"
                    :readonly="readOnly"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Patient ID"
                  label-for="patient_id"
                  label-cols-md="4"
                >
                <div class="vertical-align">
                  {{ patientInfo.patient_id }}
                </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col> -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { convertDate2Str, dateMask } from "@core/utils/utils";
import { mapState, mapActions } from "vuex";
import config from "@/config";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  timers: {
    updatePatient: config.TIMER_OPTION,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        hospital_id: null,
        // first_name: '',
        // last_name: '',
        date_of_birth: "",
        gender: "",
        height_meter: 0,
        weight_kg: 0,
        kof_dubois: "",
        // city: '',
        // zip_code: '',
        // street: '',
        // house_number: '',
        // phone: '',
        // email: '',
      },
      hospital: null,
      hospitals: [],
      genders: ["male", "female"],
      birth: "",
      gender: "",
      date: null,
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    bmi() {
      if (!this.patientInfo.height_meter) {
        this.patientInfo.bmi = "0.00";
      } else {
        this.patientInfo.bmi = (
          this.patientInfo.weight_kg /
          Math.pow(this.patientInfo.height_meter / 100, 2)
        ).toFixed(2);
      }
      return this.patientInfo.bmi;
    },
    kof() {
      this.patientInfo.kof_dubois = (
        0.007184 *
        Math.pow(this.patientInfo.weight_kg, 0.425) *
        Math.pow(this.patientInfo.height_meter, 0.725)
      ).toFixed(2);
      return this.patientInfo.kof_dubois;
    },
  },
  watch: {
    date(v, ov) {
      if (v) {
        this.patientInfo.date_of_birth = convertDate2Str(v);
      }
    },
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
    hospital(v, ov) {
      if (v && v._id) {
        this.patientInfo.hospital_id = v._id;
      }
    },
  },
  mounted() {
    this.fetchHospitals();
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  // beforeDestroy() {
  //   this.updatePatient()
  // },
  methods: {
    dateMask,
    ...mapActions("patient", ["updatePatientById"]),
    updatePatient() {
      if (this.readOnly) {
        return;
      }

      this.updatePatientById(this.patientInfo).catch((err) => {
        this.showToast(
          "danger",
          "Error",
          "An error occured while updating the patient."
        );
      });
    },
    fetchHospitals() {
      this.$http
        .get("/hospitals/list_for_update")
        .then((res) => {
          this.hospitals = res.data.hospitals;
        })
        .catch((err) => {
          this.showToast(
            "danger",
            "Error",
            "An error occured while fetching hospitals."
          );
        });
    },
    handleSave() {
      this.$http
        .patch(`/patients/update/${this.$route.params.id}`, this.patientInfo)
        .then((res) => {
          this.showToast("success", "Success", "Successfully updated.");
        })
        .catch((err) => {
          this.showToast(
            "danger",
            "Error",
            "An error occured while saving the patient."
          );
        });
    },
    setPatientInfo(v) {
      this.patientInfo._id = v._id;
      this.patientInfo.patient_id = v.patient_id;
      if (v.hospital) {
        this.hospital = { hospital_id: v.hospital.hospital_id };
        this.patientInfo.hospital_id = v.hospital._id;
      } else {
        this.hospital = null;
        this.patientInfo.hospital_id = null;
      }
      // this.patientInfo.last_name = v.last_name
      // this.patientInfo.first_name = v.first_name

      if (document.activeElement != this.$refs.date_of_birth.$el) {
        this.patientInfo.date_of_birth = v.date_of_birth;
      }

      this.patientInfo.gender = v.gender;

      this.patientInfo.height_meter = v.height_meter;
      this.patientInfo.weight_kg = v.weight_kg;
      this.patientInfo.bmi = v.bmi;
      this.patientInfo.kof_dubois = v.kof_dubois;
      // this.patientInfo.city = v.city
      // this.patientInfo.zip_code = v.zip_code
      // this.patientInfo.street = v.street
      // this.patientInfo.house_number = v.house_number
      // this.patientInfo.phone = v.phone
      // this.patientInfo.email = v.email
      // this.patientInfo.date_of_birth = v.date_of_birth ? moment(v.date_of_birth).format('DD.MM.YYYY') : ''
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.patient-info {
  .vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .bordered-card {
    border: 1px solid rgba(34, 41, 47, 0.125);
  }
}
</style>
